import React, { useEffect, useState } from "react";
import axios from "axios";
import { axiosInstance } from "../../axiosInstance/axiosInstance"

const Header = () => {
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const name = localStorage.getItem("userName");
    const role = localStorage.getItem("userRole");
    if (name) {
      setUserName(name);
    }
    if (role) {
      setUserRole(role);
    }
  }, []);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a CSV file");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    try {
      const response = await axiosInstance.post("/import", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("File uploaded successfully!");
    } catch (error) {
      alert("Error uploading file");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="head-top">
        <div className="head-top-left">
          {" "}
          <img src="/images/wavikar-logo.png" />{" "}
        </div>
        <div className="head-top-right">
          {" "}
          <h1>
            Hello  {userName} ,
            <br /> Role:{" "}
            {userRole === "1"
              ? "Superadmin"
              : userRole === "2"
                ? "Admin"
                : userRole === "3"
                  ? "Manager"
                  : "agent"}
          </h1>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <button
              style={{ marginLeft: "auto", padding: "8px 16px" }}
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload Lead"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
